export const trimBrokerName = (br: string) =>
  br.length > 8 ? br.slice(0, 5) : br

export const getPriceFormatted = (
  price: number,
  precision: number,
  highlightRange: readonly [number, number],
) => {
  const priceString = price?.toFixed(precision)

  const boldCandidate = priceString.slice(highlightRange[0], highlightRange[1])
  if (boldCandidate.includes(".")) {
    let head = priceString.slice(0, highlightRange[0] - 1)
    let bold = priceString.slice(highlightRange[0] - 1, highlightRange[1])
    // if head is the character "-" we must include also in the bold
    if (head === "-") {
      head = ""
      bold = `-${bold}`
    }

    const tail = priceString.slice(highlightRange[1])
    return [head, bold, tail]
  }
  const head = priceString.slice(0, highlightRange[0])
  const bold = priceString.slice(highlightRange[0], highlightRange[1])
  const tail = priceString.slice(highlightRange[1])
  return [head, bold, tail]
}
