import { Button } from "antd"
import styled from "styled-components"
import { colors } from "../../styles"
import * as T from "./../typography"

export const DelaysCardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background-color: ${colors.iUltraDarkGrey};
  justify-content: center;
  height: fit-content;
  min-width: 700px;
  @media (max-width: 599px) {
    min-width: 300px;
    width: 100%;
  }
`

export const DelaysCardHeader = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -9px;
`

export const DelaysCardTitleWrapper = styled.div`
  text-align: center;
`

export const DelaysCardTitle = styled(T.H3)``

export const DelaysCardButtonWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
`

export const StyledButton = styled(Button)`
  margin: 8px;
  background-color: ${colors.iPrimaryBlue};
  border-color: ${colors.iPrimaryBlue};
  :focus {
    background-color: ${colors.iPrimaryBlue};
    border-color: ${colors.iPrimaryBlue};
  }
  :hover {
    background-color: ${colors.iBlue04};
    border-color: ${colors.iBlue04};
  }
`
