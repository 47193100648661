import useResizeObserver from "@react-hook/resize-observer"
import axios from "axios"
import { Series } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { useNavigate } from "react-router-dom"
import { z } from "zod"
import {
  getBrokerPair,
  getColor,
  getWeekStart,
  mergeNewData,
  typedKeys,
  zip,
} from "../../helpers"
import indexDb from "../../helpers/indexDB"
import { breakpointNum } from "../../styles"
import {
  ExposureMarginData,
  ExposureMarginTick,
  GraphInfo,
  GraphReactComponent,
} from "../../types"
import { AuthContext } from "../auth-context"
import ExposureMarginMemoizedGraph from "../exposure-margin-memoized-graph"
import { PairsBrokersListContext } from "../used-pairs-brokers-context"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import {
  BrokerDotWrapper,
  Dot,
  ExposureMarginColored,
  ExposureMarginContentWrapper,
  ExposureMarginGrid,
  ExposureMarginRow,
  ExposureMarginValue,
  LeftExposureMarginContentWrapper,
  RightExposureMarginContentWrapper,
} from "./styled"

const exposureMarginIndexedDBValidator = z.object({
  data: z
    .object({
      broker: z.string(),
      data: z.array(z.tuple([z.number(), z.number()])),
    })
    .array(),
  savedAt: z.number(),
})

const ExposureMargin: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const [exposureMarginData, setExposureMarginData] =
    useState<ExposureMarginData>({ timestamp: 0, margins: [] })
  const { getCurrentUser } = useContext(AuthContext)
  const router = useNavigate()

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)
  const rightContentWrapper = useRef<HTMLDivElement>(null)

  const brokerPair = useMemo(() => getBrokerPair("aggregated", "all"), [])

  const { colorsMapper } = useContext(PairsBrokersListContext)

  const exposureMarginListener = useCallback(
    (tick: ExposureMarginTick) => {
      if (tick.brokerPair !== brokerPair) return

      const series: Series[] | undefined =
        chartComponentRef.current?.chart?.series
      const { timestamp, margins } = tick.data
      const nbSeriesPresent = margins.reduce(
        (acc, { broker, marginExposure }) => {
          const brokerStrip = broker.replace("-all", "")
          const serie = series?.find(x => x.name === brokerStrip)
          if (!serie) {
            chartComponentRef.current?.chart?.addSeries(
              {
                data: [[timestamp, marginExposure]],
                type: "line",
                name: brokerStrip,
                color: getColor(colorsMapper, brokerStrip),
                showInNavigator: true,
                boostThreshold: 1,
              },
              true,
              true,
            )
            return acc
          } else {
            serie.addPoint([timestamp, marginExposure], true, true)
            return acc + 1
          }
        },
        0,
      )

      if (margins.length === nbSeriesPresent)
        chartComponentRef.current?.chart.redraw()

      setExposureMarginData(tick.data)
    },
    [brokerPair, colorsMapper],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("exposureMargin", exposureMarginListener)

    return () => socket.off("exposureMargin", exposureMarginListener)
  }, [socket, graphs, exposureMarginListener])

  useEffect(() => {
    if (typedKeys(colorsMapper).length === 0) {
      console.warn("MISSING COLORS. Please Save a palette in the /settings")
      return
    }

    const asyncTrick = async () => {
      const user = await getCurrentUser()
      if (!user.isLogged) {
        router("/login")
        return
      }

      const indexDbKey = `exposureMargin-${brokerPair}`

      const loadedSafeData = exposureMarginIndexedDBValidator.safeParse(
        await indexDb.get(indexDbKey),
      )

      let cachedData: z.infer<typeof exposureMarginIndexedDBValidator> = {
        data: [],
        savedAt: 0,
      }

      if (loadedSafeData.success) {
        cachedData = loadedSafeData.data
      }

      if (cachedData.savedAt < getWeekStart().getTime()) {
        cachedData = { data: [], savedAt: 0 }
        indexDb.remove(indexDbKey)
      }

      try {
        const { data: res } = await axios.get<
          z.infer<typeof exposureMarginIndexedDBValidator>
        >(
          `${
            process.env.REACT_APP_ENDPOINT || ""
          }/liquidity-dashboard/exposure-margin`,
          {
            headers: {
              Authorization: user.tokens.token,
            },
            params: {
              from: cachedData.savedAt,
            },
          },
        )

        const brokersList = [
          ...new Set([
            ...cachedData.data.map(({ broker }) => broker),
            ...res.data.map(({ broker }) => broker),
          ]),
        ]
        const historicalData = brokersList.map(broker => {
          const oldCachedData =
            cachedData.data.find(x => x.broker === broker)?.data ?? []
          const newResData = res.data.find(x => x.broker === broker)?.data ?? []
          return {
            broker,
            data: [...oldCachedData, ...newResData],
          }
        })

        const lastPoint = {
          timestamp: Math.max(
            ...historicalData.map(x => x.data.at(-1)?.[0] || 0),
          ),
          margins: historicalData.map(x => ({
            broker: x.broker,
            marginExposure: x.data.at(-1)?.[1] ?? 0,
          })),
        }

        if (lastPoint) setExposureMarginData(lastPoint)

        indexDb.put(indexDbKey, {
          savedAt: res.savedAt,
          data: historicalData,
        })

        const series: Series[] | undefined =
          chartComponentRef.current?.chart?.series
        historicalData.forEach(({ broker, data }) => {
          const brokerStrip = broker.replace("-all", "")
          const serie = series?.find(x => x.name === brokerStrip)
          if (!serie) {
            chartComponentRef.current?.chart?.addSeries(
              {
                data: data,
                type: "line",
                name: brokerStrip,
                color: getColor(colorsMapper, brokerStrip),
                showInNavigator: true,
              },
              true,
              false,
            )
          } else {
            serie.setData(
              mergeNewData(
                data,
                zip(
                  (serie as any).xData as number[],
                  (serie as any).yData as number[],
                ),
              ),
              true,
              false,
            )
          }
        })
        chartComponentRef.current?.chart.redraw(false)
      } catch (err) {
        console.error(err)
      }
    }
    asyncTrick()
  }, [broker, getCurrentUser, router, brokerPair, colorsMapper])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "exposureMargin",
      id,
      Graph: ExposureMargin,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"))
    }, 10)
  }, [])

  useResizeObserver(rightContentWrapper, () => {
    chartComponentRef?.current?.chart?.yAxis?.[0]?.update(
      {
        visible: window.innerWidth > breakpointNum.mobile,
      },
      true,
    )
    return window.dispatchEvent(new Event("resize"))
  })

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Margin Exposure"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
      />
      <ExposureMarginContentWrapper>
        <LeftExposureMarginContentWrapper>
          <ExposureMarginGrid>
            {exposureMarginData.margins.map(({ broker, marginExposure }) => {
              const strippedBroker = broker.replace("-all", "")
              return (
                <ExposureMarginRow key={broker}>
                  <BrokerDotWrapper>
                    <Dot color={getColor(colorsMapper, strippedBroker)} />
                    <ExposureMarginValue>{strippedBroker}</ExposureMarginValue>
                  </BrokerDotWrapper>
                  <ExposureMarginColored
                    isZero={
                      marginExposure < 0.00001 && marginExposure > -0.00001
                    }
                    isHigh={marginExposure > 85}
                  >
                    {marginExposure.toLocaleString("en", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }) + "%"}
                  </ExposureMarginColored>
                </ExposureMarginRow>
              )
            })}
          </ExposureMarginGrid>
        </LeftExposureMarginContentWrapper>
        <RightExposureMarginContentWrapper
          className="immovable"
          ref={rightContentWrapper}
        >
          <ExposureMarginMemoizedGraph chartComponentRef={chartComponentRef} />
        </RightExposureMarginContentWrapper>
      </ExposureMarginContentWrapper>
    </WidgetWrapper>
  )
}

export default ExposureMargin
