import { Spin } from "antd"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { pairList } from "../../constants"
import { colors } from "../../styles"
import { TradedPairsDataType } from "../../types"
import { AuthContext } from "../auth-context"
import {
  StyledCheck,
  StyledCross,
} from "../monitoring-card-used-brokers/styled"
import CustomTooltip from "../tooltip"
import { buildErrors, buildWarnings, writeListElements } from "./helpers"
import {
  HeaderLabel,
  NotesWrapper,
  Row,
  RowValueLabel,
  Table,
  Title,
  TitleMonitoringWrapper,
  Wrapper,
} from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/monitoring/traded-pairs"

const Notes = ({ row }: { row: TradedPairsDataType[number] }) => {
  const warnings = buildWarnings(row)
  const errors = buildErrors(row)

  if (errors.length === 0 && warnings.length === 0)
    return (
      <CustomTooltip
        show={true}
        title={"No errors nor warnings."}
        color={colors.iUltraDarkGrey}
        placement={"right"}
        overlayInnerStyle={{
          borderColor: colors.iGreenText,
          borderWidth: 3,
          borderStyle: "solid",
          borderRadius: 10,
        }}
      >
        <StyledCheck width={16} />
      </CustomTooltip>
    )
  else if (errors.length !== 0)
    return (
      <CustomTooltip
        show={true}
        title={
          errors.length === 1 ? (
            errors.at(0)
          ) : (
            <ul>{writeListElements(errors)}</ul>
          )
        }
        color={colors.iUltraDarkGrey}
        placement={"right"}
        overlayInnerStyle={{
          borderColor: colors.iRed,
          borderWidth: 3,
          borderStyle: "solid",
          borderRadius: 10,
        }}
      >
        <StyledCross width={16} />
      </CustomTooltip>
    )
  else return <>{warnings}</>
}

const MonitoringTradedPairsCard = () => {
  const { getCurrentUser } = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [tradedPairsData, setTradedPairsData] = useState<TradedPairsDataType>()

  useEffect(() => {
    setIsLoading(true)
    getCurrentUser().then(user => {
      if (!user.isLogged) return
      axios
        .get<TradedPairsDataType>(url, {
          headers: {
            Authorization: user.tokens.token,
          },
        })
        .then(res => {
          setTradedPairsData(res.data)
          setIsLoading(false)
        })
        .catch(console.error)
    })
  }, [getCurrentUser])

  return (
    <Wrapper isLoading={isLoading}>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <TitleMonitoringWrapper>
            <Title>Pairs</Title>
          </TitleMonitoringWrapper>
          <Table>
            <Row className="header">
              {[
                "Pair",
                "Master Trade Volume",
                "Traded Volume",
                "Exchange Rate",
                "Swaps (Long/Short) [pip]",
                "Notes",
              ].map(label => (
                <HeaderLabel key={`${label}-header`}>{label}</HeaderLabel>
              ))}
            </Row>
            {tradedPairsData &&
              tradedPairsData.map((row, index) => {
                const thePair = pairList.find(({ id }) => id === row.pair)
                return (
                  <Row key={index}>
                    <RowValueLabel>{thePair?.label ?? ""}</RowValueLabel>
                    <RowValueLabel>
                      {row.masterTradeSize
                        ? row.masterTradeSize.toLocaleString()
                        : "-"}
                    </RowValueLabel>
                    <RowValueLabel>
                      {row.tradeSize ? row.tradeSize.toLocaleString() : "-"}
                    </RowValueLabel>
                    <RowValueLabel>
                      {row.exchangeRate
                        ? row.exchangeRate.toFixed(thePair?.bpPrecision)
                        : "-"}
                    </RowValueLabel>
                    <RowValueLabel>
                      {row.swaps
                        ? `${row.swaps.long.toFixed(
                            2,
                          )}/${row.swaps.short.toFixed(2)}`
                        : "-/-"}
                    </RowValueLabel>
                    <NotesWrapper>
                      <Notes row={row} />
                    </NotesWrapper>
                  </Row>
                )
              })}
          </Table>
        </>
      )}
    </Wrapper>
  )
}

export default MonitoringTradedPairsCard
