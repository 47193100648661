import { Spin } from "antd"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { colors } from "../../styles"
import { UsedBrokersDataResponseType, UsedBrokersDataType } from "../../types"
import { AuthContext } from "../auth-context"
import CustomTooltip from "../tooltip"
import { buildErrors, buildWarnings, writeListElements } from "./helpers"
import {
  HeaderLabel,
  HexagonStyled,
  NotesWrapper,
  Row,
  RowValueImg,
  RowValueLabel,
  StyledCheck,
  StyledCross,
  Table,
  Title,
  TitleMonitoringWrapper,
  Wrapper,
} from "./styled"

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/monitoring/used-brokers"

const Notes = ({ row }: { row: UsedBrokersDataType[number] }) => {
  const warnings = buildWarnings(row)
  const errors = buildErrors(row)

  if (errors.length === 0 && warnings.length === 0)
    return (
      <CustomTooltip
        show={true}
        title={"No errors nor warnings."}
        color={colors.iUltraDarkGrey}
        placement={"right"}
        overlayInnerStyle={{
          borderColor: colors.iGreenText,
          borderWidth: 3,
          borderStyle: "solid",
          borderRadius: 10,
        }}
      >
        <StyledCheck width={16} />
      </CustomTooltip>
    )
  else if (errors.length !== 0)
    return (
      <CustomTooltip
        show={true}
        title={
          errors.length === 1 ? (
            errors.at(0)
          ) : (
            <ul>{writeListElements(errors)}</ul>
          )
        }
        color={colors.iUltraDarkGrey}
        placement={"right"}
        overlayInnerStyle={{
          borderColor: colors.iRed,
          borderWidth: 3,
          borderStyle: "solid",
          borderRadius: 10,
        }}
      >
        <StyledCross width={16} />
      </CustomTooltip>
    )
  else return <>{warnings}</>
}

const MonitoringUsedBrokersCard = () => {
  const { getCurrentUser } = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [usedBrokersData, setUsedBrokersData] = useState<UsedBrokersDataType>()

  useEffect(() => {
    setIsLoading(true)
    getCurrentUser().then(user => {
      if (!user.isLogged) return
      axios
        .get<UsedBrokersDataResponseType>(url, {
          headers: {
            Authorization: user.tokens.token,
          },
        })
        .then(res => {
          const usedBrokers = res.data
            .filter(b => !!b.consolidated)
            .sort((a, b) => (a.broker > b.broker ? +1 : -1))
          const nonUsedBrokers = res.data
            .filter(b => !b.consolidated)
            .sort((a, b) => (a.broker > b.broker ? +1 : -1))

          setUsedBrokersData([...usedBrokers, ...nonUsedBrokers])
          setIsLoading(false)
        })
        .catch(console.error)
    })
  }, [getCurrentUser])

  return (
    <Wrapper isLoading={isLoading}>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <TitleMonitoringWrapper>
            <Title>Used Brokers</Title>
          </TitleMonitoringWrapper>
          <Table>
            <Row className="header">
              {["Consolidated", "Broker", "dpm", "POP", "notes"].map(label => (
                <HeaderLabel key={`${label}-header`}>{label}</HeaderLabel>
              ))}
            </Row>
            {usedBrokersData &&
              usedBrokersData.map((row, index) => (
                <Row key={index}>
                  <RowValueImg>
                    {row?.consolidated != null ? (
                      <HexagonStyled $isUsed={!!row.consolidated} />
                    ) : null}
                  </RowValueImg>
                  <RowValueLabel>{row.broker}</RowValueLabel>
                  <RowValueLabel>
                    {row?.dpm !== null ? row.dpm.toFixed(1) : "-"}
                  </RowValueLabel>
                  <RowValueLabel>{row.POP ?? "-"}</RowValueLabel>
                  <NotesWrapper>
                    <Notes row={row} />
                  </NotesWrapper>
                </Row>
              ))}
          </Table>
        </>
      )}
    </Wrapper>
  )
}

export default MonitoringUsedBrokersCard
