import { useCallback, useEffect, useMemo, useState } from "react"
import { getBrokerPair } from "../../helpers"
import {
  BalanceEquityData,
  BalanceEquityTick,
  GraphInfo,
  GraphReactComponent,
} from "../../types"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { formatExposure, formatVolume, unitCost } from "./helpers"
import {
  BalanceEquityColored,
  BalanceEquityColoredBig,
  BalanceEquityContentWrapper,
  BalanceEquityGrid,
  BalanceEquityLabel,
  BalanceEquityLabelBig,
  BalanceEquityLabelText,
  BalanceEquityRow,
  BalanceEquityRowBig,
  DotsFlashing,
  LeftBalanceEquityContentWrapper,
} from "./styled"

const defaultBalanceEquityData = {
  timestamp: 0,
  brokerPair: "",
  pnlUSD: 0,
  balance: 0,
  exposure: undefined,
  totalCosts: undefined,
  volume: undefined,
}

const BalanceAndEquityMini: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const [balanceEquityData, setBalanceEquityData] = useState<
    BalanceEquityData[number]
  >(defaultBalanceEquityData)
  const brokerPair = useMemo(() => getBrokerPair("aggregated", "all"), [])
  const balanceEquityListener = useCallback(
    (tick: BalanceEquityTick) => {
      if (tick.brokerPair !== brokerPair) return

      const dataToPrint = tick.data.at(0)
      if (!dataToPrint) return

      setBalanceEquityData(dataToPrint)
    },
    [brokerPair],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("balanceEquityMini", balanceEquityListener)

    return () => socket.off("balanceEquityMini", balanceEquityListener)
  }, [socket, graphs, balanceEquityListener])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "balanceEquityMini",
      id,
      Graph: BalanceAndEquityMini,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Mini Current status (USD)"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
      />
      <BalanceEquityContentWrapper>
        <LeftBalanceEquityContentWrapper>
          <BalanceEquityGrid>
            <BalanceEquityRowBig>
              <BalanceEquityLabelBig>Equity</BalanceEquityLabelBig>
              <BalanceEquityColoredBig
                isZero={
                  balanceEquityData.balance + balanceEquityData.pnlUSD <
                    0.00001 &&
                  balanceEquityData.balance + balanceEquityData.pnlUSD >
                    -0.00001
                }
                isNegative={
                  balanceEquityData.balance + balanceEquityData.pnlUSD < 0
                }
              >
                {(
                  balanceEquityData.balance + balanceEquityData.pnlUSD
                ).toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                  currencyDisplay: "narrowSymbol",
                })}
              </BalanceEquityColoredBig>
            </BalanceEquityRowBig>
            <BalanceEquityRow>
              <BalanceEquityLabelText>Floating</BalanceEquityLabelText>
              <BalanceEquityColored
                isZero={
                  balanceEquityData.pnlUSD < 0.00001 &&
                  balanceEquityData.pnlUSD > -0.00001
                }
                isNegative={balanceEquityData.pnlUSD < 0}
              >
                {balanceEquityData.pnlUSD.toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                  currencyDisplay: "narrowSymbol",
                })}
              </BalanceEquityColored>
            </BalanceEquityRow>
            <BalanceEquityRow>
              <BalanceEquityLabelText>Balance</BalanceEquityLabelText>
              <BalanceEquityLabel>
                {balanceEquityData.balance.toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                  currencyDisplay: "narrowSymbol",
                })}
              </BalanceEquityLabel>
            </BalanceEquityRow>
            <div style={{ height: "40px" }} />
            <BalanceEquityRow>
              <BalanceEquityLabelText active={true}>
                Cost
              </BalanceEquityLabelText>
              {balanceEquityData.totalCosts !== undefined &&
              balanceEquityData.totalCosts !== null ? (
                <BalanceEquityColored
                  isZero={
                    balanceEquityData.totalCosts < 0.00001 &&
                    balanceEquityData.totalCosts > -0.00001
                  }
                  isNegative={balanceEquityData.totalCosts < 0}
                >
                  {balanceEquityData.totalCosts.toLocaleString("en", {
                    style: "decimal",
                    currency: "USD",
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }) + unitCost}
                </BalanceEquityColored>
              ) : (
                <DotsFlashing />
              )}
            </BalanceEquityRow>
            <BalanceEquityRow>
              <BalanceEquityLabelText active={true}>
                Volume
              </BalanceEquityLabelText>
              {balanceEquityData.volume !== undefined &&
              balanceEquityData.volume !== null ? (
                <BalanceEquityLabel>
                  {formatVolume(balanceEquityData.volume)}
                </BalanceEquityLabel>
              ) : (
                <DotsFlashing />
              )}
            </BalanceEquityRow>
            <BalanceEquityRow>
              <BalanceEquityLabelText>Exposure</BalanceEquityLabelText>
              {balanceEquityData.exposure !== undefined ? (
                <BalanceEquityLabel>
                  {formatExposure(Math.abs(balanceEquityData.exposure))}
                </BalanceEquityLabel>
              ) : (
                <DotsFlashing />
              )}
            </BalanceEquityRow>
          </BalanceEquityGrid>
        </LeftBalanceEquityContentWrapper>
      </BalanceEquityContentWrapper>
    </WidgetWrapper>
  )
}

export default BalanceAndEquityMini
