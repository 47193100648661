const formatter = Intl.NumberFormat("en", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})

const cryptoFormatter = Intl.NumberFormat("en", {
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

export const formatTableWft = (value: number) => formatter.format(value)

const formatterVolume = Intl.NumberFormat("en", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
})

export const formatVolumeWftLabel = (volume: number) =>
  process.env.REACT_APP_INSTANCE_TYPE === "crypto"
    ? cryptoFormatter.format(volume) + " BTC"
    : formatterVolume.format(volume / 1_000_000) + "m"

export const formatVolumeWft = (volume: number) =>
  process.env.REACT_APP_INSTANCE_TYPE === "crypto"
    ? volume
    : Math.round(volume / 100_000) / 10

export const pointVolume =
  process.env.REACT_APP_INSTANCE_TYPE === "crypto"
    ? "{point.y:.2f} BTC"
    : "&dollar;{point.y}m"

const formatterDeals = Intl.NumberFormat("en", {
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
})
export const formatDealsWft = (value: number) => formatterDeals.format(value)
