import { Spin } from "antd"
import axios from "axios"
import highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
import { useCallback, useContext, useEffect, useRef, useState } from "react"
import { DelayDataType, DelaysDataResponseType } from "../../types"
import { AuthContext } from "../auth-context"
import {
  getSortedBrokers,
  getUniqueNonUsedBrokers,
  getUniquepairs,
  getUniqueUsedBrokers,
  reshapeDelaysData,
} from "./helpers"

import HighchartsHeatmap from "highcharts/modules/heatmap"
import {
  DelaysCardButtonWrapper,
  DelaysCardHeader,
  DelaysCardTitle,
  DelaysCardTitleWrapper,
  DelaysCardWrapper,
  StyledButton,
} from "./styled"
import QuoteDelaysMemoizedGraph from "../quotes-delays-memoized-graph"

HighchartsHeatmap(highcharts)

const url =
  (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
  "/monitoring/delays"

const MonitoringDelaysCard = () => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null)

  const { getCurrentUser } = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [delaysData, setDelaysData] = useState<DelayDataType>()

  const getDelaysData = useCallback(
    () =>
      getCurrentUser()
        .then(user => {
          if (!user.isLogged) return
          axios
            .get<DelaysDataResponseType>(url, {
              headers: {
                Authorization: user.tokens.token,
              },
            })
            .then(({ data }) => {
              const uniqueUsedBrokers = getUniqueUsedBrokers(data)
              const uniqueNonUsedBrokers = getUniqueNonUsedBrokers(data)
              const sortedBrokers = getSortedBrokers(
                uniqueUsedBrokers,
                uniqueNonUsedBrokers,
              )

              const uniquePairs = getUniquepairs(data)

              const newDelaysData = reshapeDelaysData(
                data,
                uniquePairs,
                sortedBrokers,
              )
              setDelaysData(newDelaysData)
            })
        })
        .catch(console.error),
    [getCurrentUser],
  )

  useEffect(() => {
    setIsLoading(true)
    getDelaysData().finally(() => setIsLoading(false))
  }, [getDelaysData])

  return (
    <DelaysCardWrapper>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <DelaysCardHeader>
            <DelaysCardTitleWrapper>
              <DelaysCardTitle>Time since latest quote</DelaysCardTitle>
            </DelaysCardTitleWrapper>
            <DelaysCardButtonWrapper>
              <StyledButton type="primary" onClick={getDelaysData}>
                Refresh
              </StyledButton>
            </DelaysCardButtonWrapper>
          </DelaysCardHeader>
          {delaysData && (
            <QuoteDelaysMemoizedGraph
              chartComponentRef={chartComponentRef}
              delaysData={delaysData}
            />
          )}
        </>
      )}
    </DelaysCardWrapper>
  )
}

export default MonitoringDelaysCard
