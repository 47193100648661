import HighchartsReact from "highcharts-react-official"
import * as HighchartsStock from "highcharts/highstock"
import HighchartsBoost from "highcharts/modules/boost"
import lastPriceIndicator from "highcharts/modules/price-indicator"
import React from "react"
import { colors } from "../../styles"
import {
  getHorizontalMarkerWidth,
  labelToPipFormatter,
  getHorizontalLabelPadding,
} from "./helpers"

lastPriceIndicator(HighchartsStock)
HighchartsBoost(HighchartsStock)

const defaultSeriesOptions = {
  type: "line",
  showInNavigator: true,
  dataGrouping: {
    enabled: false,
  },
  boostThreshold: 1,
  zIndex: 6,
  lineWidth: 1,
  states: {
    hover: {
      enabled: false,
    },
  },
  color: colors.iPrimaryBlue,
}

const MemoizedSpreadGraph = ({
  chartComponentRef,
  precision,
  loadedHistorical,
  setExtremes,
  rangeMin,
  rangeMax,
}: {
  chartComponentRef: React.RefObject<HighchartsReact.RefObject>
  precision: number
  loadedHistorical: boolean
  setExtremes: Highcharts.AxisSetExtremesEventCallbackFunction
  rangeMin: number | undefined
  rangeMax: number | undefined
}) => (
  <HighchartsReact
    highcharts={HighchartsStock}
    constructorType={"StockChart"}
    containerProps={{
      className: "chartContainer",
      style: {
        height: "100%",
        width: "100%",
      },
    }}
    options={{
      accessibility: {
        enabled: false,
      },
      boost: {
        usePreallocated: loadedHistorical ? true : false,
      },
      chart: {
        zooming: {
          type: "y",
          singleTouch: true,
        },
        backgroundColor: colors.iUltraDarkGrey,
        spacingLeft: 0,
        spacingRight: 0,
        events: {
          redraw: () => {
            const chart = chartComponentRef.current?.chart
            if (!chart) return
            chart.yAxis[0].removePlotLine("max-line")
            chart.yAxis[0].removePlotLine("min-line")
            chart.yAxis[0].removePlotLine("actual")

            chart.yAxis[0].addPlotLine({
              value: chart.yAxis[0].getExtremes().dataMax,
              color: colors.iGrey02,
              dashStyle: "Dash",
              width: 1,
              id: "max-line",
              zIndex: 4,
              label: {
                text: (chart.yAxis[0].getExtremes().dataMax ?? 0) // * 10 ** (precision - 1)
                  .toFixed(precision + 1),
                style: {
                  color: colors.iGrey02,
                },
              },
            })
            chart.yAxis[0].addPlotLine({
              value: chart.yAxis[0].getExtremes().dataMin,
              color: colors.iGrey02,
              dashStyle: "Dash",
              width: 1,
              zIndex: 4,
              id: "min-line",
              label: {
                text: (chart.yAxis[0].getExtremes().dataMin ?? 0) // * 10 ** (precision - 1)
                  .toFixed(precision + 1),
                style: {
                  color: colors.iGrey02,
                },
              },
            })

            const latestVal =
              (chart.yAxis[0].series[0] as any)?.processedYData?.at(-1) ?? 0

            if (latestVal !== undefined)
              chart.yAxis[0].addPlotLine({
                value: latestVal,
                color: colors.iGrey01,
                width: 1,
                id: "actual",
                label: {
                  style: {
                    color: colors.iWhite,
                    fontSize: "11px",
                    zIndex: 1,
                  },
                  align: "right",
                  formatter: () =>
                    `<div style="background-image: url('/icons/stock-marker.png'); height: 22px; width: ` +
                    getHorizontalMarkerWidth(precision) +
                    `px; background-size: cover; text-align: right; padding-top: 2px; padding-right: ${getHorizontalLabelPadding(precision)}px;">
                  ${latestVal //* 10 ** (precision - 1)
                    .toFixed(precision + 1)}</div>`,
                  useHTML: true,
                  x: precision === 5 ? 70 : 50,
                  y: 2,
                },
              })

            const latestVal5000 =
              (chart.yAxis[0].series[1] as any)?.processedYData?.at(-1) ?? 0

            if (latestVal5000 !== undefined)
              chart.yAxis[0].addPlotLine({
                value: latestVal5000,
                color: colors.iGrey01,
                width: 1,
                id: "actual",
                label: {
                  style: {
                    color: colors.iWhite,
                    fontSize: "11px",
                    zIndex: 0,
                  },
                  align: "right",
                  formatter: () =>
                    `<div style="background-image: url('/icons/stock-marker-red.png'); height: 22px; width: ` +
                    getHorizontalMarkerWidth(precision) +
                    `px; background-size: cover; text-align: right; padding-top: 2px; padding-right: ${getHorizontalLabelPadding(precision)}px;">
                ${latestVal5000 //* 10 ** (precision - 1)
                  .toFixed(precision + 1)}</div>`,
                  useHTML: true,
                  x: precision === 5 ? 70 : 50,
                  y: 2,
                },
              })
          },
        },
      },
      ...(loadedHistorical
        ? {
            navigator: {
              enabled: true,
              height: 25,
            },
            rangeSelector: {
              adaptToUpdatedData: true,
              enabled: true,
              stickToMax: true,
              inputPosition: {
                align: "center",
              },
              buttons: [
                {
                  type: "minute",
                  count: 5,
                  text: "5m",
                },
                {
                  type: "minute",
                  count: 30,
                  text: "30m",
                },
                {
                  type: "hour",
                  count: 1,
                  text: "1h",
                },
                {
                  type: "hour",
                  count: 6,
                  text: "6h",
                },
                {
                  type: "hour",
                  count: 12,
                  text: "12h",
                },
                {
                  type: "all",
                  text: "All",
                },
              ],
              ...(rangeMin && rangeMax ? {} : { selected: 0 }),
            },
            scrollbar: {
              enabled: true,
            },
          }
        : {
            navigator: {
              enabled: false,
            },
            rangeSelector: {
              enabled: false,
            },
            scrollbar: {
              enabled: false,
            },
          }),
      xAxis: {
        gridLineWidth: 0,
        lineColor: colors.iDarkGrey,
        tickWidth: 0,
        type: "datetime",
        ordinal: false,
        events: {
          afterSetExtremes: setExtremes,
        },
      },
      yAxis: [
        {
          opposite: false,
          endOnTick: false,
          gridLineColor: colors.iDarkGrey,
          labels: {
            formatter: labelToPipFormatter(precision),
          },
        },
        {
          endOnTick: false,
          labels: {
            x: -3,
            align: "right",
            reserveSpace: true,
            formatter: labelToPipFormatter(precision),
          },
          gridLineColor: colors.iDarkGrey,
          linkedTo: 0,
          opposite: true,
          offset: 15,
        },
      ],
      plotOptions: {
        series: {
          animation: false,
          enableMouseTracking: false,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
      tooltip: {
        enabled: false,
        animation: false,
      },
      series: [
        defaultSeriesOptions,
        {
          ...defaultSeriesOptions,
          color: colors.iRed,
        },
      ],
    }}
    ref={chartComponentRef}
  />
)
export default React.memo(MemoizedSpreadGraph)
