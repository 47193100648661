import MonitoringDelaysCard from "../../components/monitoring-card-delays"
import MonitoringTradedPairsCard from "../../components/monitoring-card-traded-pairs"
import MonitoringUsedBrokersCard from "../../components/monitoring-card-used-brokers"
import MonitoringWebFxComparisonCard from "../../components/monitoring-card-webFx-comparison"
import { MonitoringCardsWrapper } from "./styled"

export const Monitoring = () => {
  return (
    <MonitoringCardsWrapper>
      <MonitoringUsedBrokersCard />
      <MonitoringTradedPairsCard />
      <MonitoringWebFxComparisonCard compare="numberOfDeals" />
      <MonitoringWebFxComparisonCard compare="balance" />
      <MonitoringDelaysCard />
    </MonitoringCardsWrapper>
  )
}
