const formatter = Intl.NumberFormat("en", {
  style: "decimal", //"currency",
  currency: "USD",
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
})

export const formatCostsWft = (value: number) =>
  process.env.REACT_APP_INSTANCE_TYPE === "crypto"
    ? formatter.format(value) + "$/BTC"
    : formatter.format(value) + "$pm"
